import { httpPost } from "@/api/request";
import { useStoreUser } from "@/store";

// 获取安装列表
export function GET_GOODS_INSTALL_LIST(params: object) {
    return httpPost("/install/list", params, {
        headers: {
            Authorization: useStoreUser().getToken,
        },
    });
}
